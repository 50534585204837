import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@app/types/Ads.d";
import Advertisement from "@components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import { twMerge } from "tailwind-merge";

type AdsImuProps = {
  uniqueSectionName?: string;
  rootClassName?: string;
  pageAdTargetType?: PageAdTargetValue;
  isArticlePage?: boolean;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 */

export function AdsImu1({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  isArticlePage = false,
  gsChannels,
  articleSlotTarget,
}: AdsImuProps) {
  const PubPageAdTarget =
    pageAdTargetType &&
    (pubPageAdTargeting[pageAdTargetType] as AdTargetingType<
      string,
      PageAdTargetValue
    >);
  return isArticlePage && gsChannels?.length == 0 ? (
    <></>
  ) : (
    <Advertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      adUnitProps={{
        type: AdvertisementTypeEnum.IMU1,
        slot: GoogleAdsSlotFactory.imu1(uniqueSectionName),
        ...(PubPageAdTarget && { pubAdTargeting: [PubPageAdTarget] }),
        gsChannels,
        articleSlotTarget,
      }}
    />
  );
}
export function AdsImu2({
  uniqueSectionName,
  pageAdTargetType,
  isArticlePage = false,
  gsChannels,
  articleSlotTarget,
}: AdsImuProps) {
  const PubPageAdTarget =
    pageAdTargetType &&
    (pubPageAdTargeting[pageAdTargetType] as AdTargetingType<
      string,
      PageAdTargetValue
    >);
  return isArticlePage && gsChannels?.length == 0 ? (
    <></>
  ) : (
    <div className="lg:h-[600px]">
      <Advertisement
        rootClassName={twMerge(
          "flex justify-center lg:sticky lg:top-[130px]",
          "!py-0",
        )}
        adUnitProps={{
          type: AdvertisementTypeEnum.IMU2,
          slot: GoogleAdsSlotFactory.imu2(uniqueSectionName),
          ...(PubPageAdTarget && { pubAdTargeting: [PubPageAdTarget] }),
          gsChannels,
          articleSlotTarget,
        }}
      />
    </div>
  );
}

export function AdsImu3({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  isArticlePage = false,
  gsChannels,
  articleSlotTarget,
}: AdsImuProps) {
  const PubPageAdTarget =
    pageAdTargetType &&
    (pubPageAdTargeting[pageAdTargetType] as AdTargetingType<
      string,
      PageAdTargetValue
    >);
  return isArticlePage && gsChannels?.length == 0 ? (
    <></>
  ) : (
    <Advertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      adUnitProps={{
        type: AdvertisementTypeEnum.IMU3,
        slot: GoogleAdsSlotFactory.imu3(uniqueSectionName),
        ...(PubPageAdTarget && { pubAdTargeting: [PubPageAdTarget] }),
        gsChannels,
        articleSlotTarget,
      }}
    />
  );
}

export function AdsMidArticle({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  isArticlePage = false,
  gsChannels,
  articleSlotTarget,
}: AdsImuProps) {
  const PubPageAdTarget =
    pageAdTargetType &&
    (pubPageAdTargeting[pageAdTargetType] as AdTargetingType<
      string,
      PageAdTargetValue
    >);
  return isArticlePage && gsChannels?.length == 0 ? (
    <></>
  ) : (
    <Advertisement
      rootClassName={twMerge("block text-center", rootClassName)}
      adUnitProps={{
        type: AdvertisementTypeEnum.MIDARTICLESPECIAL,
        slot: GoogleAdsSlotFactory.midarticlespecial(uniqueSectionName),
        ...(PubPageAdTarget && { pubAdTargeting: [PubPageAdTarget] }),
        gsChannels,
        articleSlotTarget,
        rootClassName: "",
      }}
    />
  );
}
