/**
 * Company: SPHMedia
 * Description: [Home UGC widet]
 */
import { ReactElement } from "react";

/**
 * UGCWidget
 * Description: UGC widget on home page
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export function UGCWidget({
  homeUGCWidgetContent = "",
}: {
  homeUGCWidgetContent: string | undefined;
}): ReactElement {
  return <div dangerouslySetInnerHTML={{ __html: homeUGCWidgetContent }} />;
}
