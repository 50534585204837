import { BH_BASE_URL, GRAPESHOT_API_ENDPOINT } from "@helper/getEnvVariables";
import {
  removeLeadingSlash,
  removeTrailingSlash,
} from "@helper/removeTrailingLeadingSlash";
import { useCallback, useEffect, useMemo, useState } from "react";

type GrapeshotResult = {
  channels: {
    name: string;
  }[];
};

export function useGrapeshotData(urlPath: string) {
  const [gsChannels, setGsChannels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchGsChannels = useCallback(
    async (urlPath: string): Promise<string[]> => {
      try {
        const response = await fetch(
          `${GRAPESHOT_API_ENDPOINT}${removeTrailingSlash(BH_BASE_URL)}/${removeLeadingSlash(urlPath)}`,
          {
            method: "GET",
          },
        );

        const processedData = (await response.json()) as GrapeshotResult;
        const channelNames = processedData.channels.map(
          (channel) => channel.name,
        );
        return channelNames;
      } catch (e) {
        return [];
      }
    },
    [],
  );

  const fetchWithTimeout = useCallback(
    async (
      promise: Promise<string[]>,
      timeoutMs: number,
    ): Promise<string[]> => {
      return Promise.race([
        promise,
        new Promise<string[]>((resolve) =>
          setTimeout(() => {
            resolve([]);
          }, timeoutMs),
        ),
      ]);
    },
    [],
  );

  useEffect(() => {
    const callFetch = async () => {
      if (urlPath === "") {
        setIsLoading(false);
        return;
      }

      const gsData = await fetchWithTimeout(fetchGsChannels(urlPath), 1000); // wait for only 1 sec.
      setGsChannels(gsData);
      setIsLoading(false);
    };

    void callFetch();
  }, [urlPath, fetchWithTimeout, fetchGsChannels]);

  return useMemo(() => ({ gsChannels, isLoading }), [gsChannels, isLoading]);
}
