import { GalleryCarouselTypes } from "@app/types/Opensearch.d";
import ArticleCarousel from "@components/Carousel/ArticleCarousel";
import SideBlockAds from "@components/Container/SideBlockAds";
import LabelCardIcon from "@components/Icon/LabelCardIcon";
import ArticleParagraph from "@elements/ArticleParagraph/ArticleParagraph";
import AttachmentCard from "@elements/AttachmentCard/AttachmentCard";
import AuthorCard from "@elements/Card/Author/AuthorCard";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import ShareSocial, { SocialVariant } from "@elements/ShareSocial/ShareSocial";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import Link from "@elements/Typography/Link";
import { StandFirst } from "@elements/Typography/Paragraph";
import { RenderTags } from "@elements/Typography/Tags";
import { SectionTitleV2 } from "@elements/Typography/TitleV2";
import { isArrayWithElements } from "@helper/utils";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { AdTargetingType, ArticleAdTargetKeys } from "@typings/Ads.d";

export type ArticleContentCardProps = {
  mainArticle: ProcessedArticleData;
  latestRegisteredArticle: ProcessedArticleData[];
  pageNumber: number;
  isInfiniteScrollArticle?: boolean;
  isOutbrainEnabled: boolean;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  index?: number;
  visibleArticleId?: string;
};

export default function ArticleContentCard({
  mainArticle,
  latestRegisteredArticle,
  pageNumber,
  isInfiniteScrollArticle = false,
  isOutbrainEnabled = false,
  gsChannels,
  articleSlotTarget,
  index,
  visibleArticleId,
}: ArticleContentCardProps) {
  const {
    sectionName,
    uniqueSectionName,
    title,
    tags,
    authors,
    date,
    dateCreated,
    time,
    articleBody,
    standFirst,
    contentAccess,
    urlPath,
    imageGallery,
    relatedArticles,
    attachments,
    id,
    linkToSection,
    adsUniqueSectionName,
  } = mainArticle;
  const socialIconList = ["facebook", "telegram", "plainWhatsapp", "newsPost"];
  const pageUrl = `${urlPath}`;
  const renderItems = imageGallery.filter(
    (item) => item.type !== GalleryCarouselTypes.UNKNOWN,
  );

  return (
    <div key={index} className="flex flex-col lg:flex-row w-full gap-6">
      <div className="flex flex-col lg:px-xs">
        <div className="flex items-center gap-3 w-full">
          <SectionTitleV2
            Element={Link}
            elementLink={linkToSection}
            className="text-[23px] font-[400] mb-0"
            sectionName={sectionName}
            parentSection={mainArticle.parentSection}
            iconStyle="hidden"
          />
          <LabelCardIcon contentAccess={contentAccess} />
        </div>
        <Heading
          Element="h1"
          className="font-secondary leading-2xl text-black-200 text-xl lg:text-4xl font-bold"
        >
          {title}
        </Heading>
        {standFirst && (
          <StandFirst className="my-3 lg:my-4">{standFirst}</StandFirst>
        )}
        {/* Tags dont dont render If alias not available in payload*/}
        <RenderTags tagsWrapper="my-3" tags={tags} />
        {isArrayWithElements(authors) && (
          <div className="flex flex-row gap-2 flex-wrap my-3">
            {authors.map(
              (author, index) =>
                author.hasProfile && (
                  <AuthorCard
                    key={index}
                    author={author}
                    displayHeight={36}
                    displayWidth={36}
                    imageWrapper="w-[50px]"
                    authorCardWrapper="gap-2"
                    hasEmail={false}
                    forAuthorListing={false}
                  />
                ),
            )}
          </div>
        )}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-3">
          <Date date={date} time={time} variant={"or"} className="my-2" />

          <div className="flex self-end gap-1">
            {socialIconList.map((variant, index) => (
              <ShareSocial
                variant={variant as SocialVariant}
                pagePath={pageUrl}
                pageTitle={title}
                dateCreated={dateCreated}
                key={index}
              />
            ))}
          </div>
        </div>
        {isArrayWithElements(renderItems) && (
          <ArticleCarousel
            renderItems={renderItems}
            sectionName={sectionName}
          />
        )}

        <ArticleParagraph
          freeArticle={mainArticle}
          articleId={id}
          contentAccess={contentAccess}
          articleBody={articleBody}
          uniqueSectionName={uniqueSectionName}
          relatedArticles={relatedArticles}
          latestRegisteredArticle={latestRegisteredArticle}
          pageNumber={pageNumber}
          isInfiniteScrollArticle={isInfiniteScrollArticle}
          gsChannels={gsChannels}
          articleSlotTarget={articleSlotTarget}
          visibleArticleId={visibleArticleId}
          adsUniqueSectionName={adsUniqueSectionName}
        />

        {isArrayWithElements(attachments) && (
          <div className="my-md">
            {attachments.map((attachment, idx) => (
              <AttachmentCard key={idx} attachment={attachment} />
            ))}
          </div>
        )}

        <div className="flex self-end gap-1 mt-6">
          {socialIconList.map((variant, index) => (
            <ShareSocial
              variant={variant as SocialVariant}
              pagePath={pageUrl}
              pageTitle={title}
              dateCreated={dateCreated}
              key={index}
            />
          ))}
        </div>
        {isOutbrainEnabled && urlPath && <Outbrain pathName={urlPath} />}
      </div>

      <SideBlockAds
        key={index}
        adsSectionName={adsUniqueSectionName}
        className="w-full max-w-[400px] py-xs px-6 sticky top-[130px]"
        adsOnly={true}
        hasImu3={false}
        forInfiniteScroll={isInfiniteScrollArticle}
        pageNumber={pageNumber}
        isArticlePage={true}
        gsChannels={gsChannels}
        articleSlotTarget={articleSlotTarget}
        index={index}
      />
    </div>
  );
}
