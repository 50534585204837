import registeredUser from "@assets/header/menu-selected/icon/registered-user-new.svg";
import { PodcastBHCustomEventCategory } from "@components/Gallery/PodcastGallery";
import HorizontalStoryPackage from "@components/HomePageStoryPackages/HorizontalStoryPackage";
import PodcastPackage from "@components/HomePageStoryPackages/MediaPackages/PodcastPackage";
import VideoPackage from "@components/HomePageStoryPackages/MediaPackages/VideoPackage";
import VerticalStoryPackage from "@components/HomePageStoryPackages/VerticalStoryPackage";
import { AdsLb2 } from "@elements/Advertisement/variant/AdsLb";
import { CompanionAdsImu1 } from "@elements/Advertisement/variant/CompanionAdImu";
import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import { UGCWidget } from "@pages/Home/Components/UGCWidget";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import { GEWidget, GEWidgetProps } from "../GE2025/GEWidgets";
import WidgetWrapper from "./WidgetWrapper";

export type HomePageNewProps = {
  topStoryQueue: ProcessedArticleData[];
  latestFreeArticle: ProcessedArticleData[];
  latestSgArticle: ProcessedArticleData[];
  latestMaArticle: ProcessedArticleData[];
  kakiJalanArticle: ProcessedArticleData[];
  kakiMakanArticle: ProcessedArticleData[];
  dnaDuniaArticle: ProcessedArticleData[];
  latestGayaArticle: ProcessedArticleData[];
  registeredArticle: ProcessedArticleData[];
  latestDuniaArticle: ProcessedArticleData[];
  adsSectionName: string;
  pageAdTargetValue: PageAdTargetingTypeEnum;
  gePayload?: GEWidgetProps;
  UGCBanner?: string;
};
export default function HomePageNew({
  topStoryQueue,
  latestFreeArticle,
  latestSgArticle,
  latestMaArticle,
  kakiJalanArticle,
  kakiMakanArticle,
  dnaDuniaArticle,
  latestGayaArticle,
  registeredArticle,
  latestDuniaArticle,
  adsSectionName,
  pageAdTargetValue,
  gePayload,
  UGCBanner,
}: HomePageNewProps) {
  return (
    <>
      <div className="bg-white-200">
        <WidgetWrapper>
          <GEWidget {...gePayload} />
        </WidgetWrapper>
      </div>
      <WidgetWrapper>
        <VerticalStoryPackage
          renderItems={topStoryQueue}
          styles={{
            wrapperStyle: "border-t-0",
          }}
          firstArticleProps={{
            hasParagraph: true,
            hasSectionName: true,
            hasNewIcon: true,
          }}
          restArticleProps={{
            hasParagraph: false,
            hasSectionName: true,
            hasNewIcon: true,
          }}
        />
      </WidgetWrapper>
      <div className="w-full bg-white-200">
        <AdsLb2
          uniqueSectionName={adsSectionName}
          pageAdTargetType={pageAdTargetValue}
          hasStripeBg={false}
        />
      </div>
      <WidgetWrapper>
        <HorizontalStoryPackage
          renderItems={latestFreeArticle}
          styles={{
            wrapperStyle: "border-t-0",
            cardWrapperStyle: "pb-0",
            cardInnerWrapperStyle:
              "flex-col md:grid md:grid-cols-2  xl:flex xl:flex-row gap-[20px] w-full",
            cardWrapper:
              "flex flex-row gap-xs xl:gap-0 xl:flex-col xl:items-center justify-center xl:basis-1/4",
            cardHeaderWrapper:
              "basis-3/10 md:basis-2/5 lg:basis-3/10 block w-full",
            cardContentWrapper:
              "basis-7/10 md:basis-3/5 lg:basis-7/10 w-full mb-0 gap-xs text-xxs",
            cardDateTitleWrapper: "flex flex-col-reverse gap-sm",
            sectionNameWrapper: "flex justify-between",
            sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
            imageWidth: 284,
            imageHeight: 195,
          }}
        />
      </WidgetWrapper>
      <WidgetWrapper>
        <VerticalStoryPackage
          renderItems={latestSgArticle}
          title="Singapura"
          moreLink={{
            destination: "singapura",
          }}
          styles={{
            wrapperStyle: "border-red-100",
            titleStyle: "text-red-100",
          }}
        />
      </WidgetWrapper>
      <div className="w-full bg-white-200">
        <CompanionAdsLb
          uniqueSectionName={adsSectionName}
          index={1}
          pageAdTargetType={pageAdTargetValue}
          stripeBackground={false}
          overRideSlot={false}
          wrapperStyle="flex-col xl:flex-row max-w-full mb-4 min-h-[80px] lg:min-h-[120px]"
          rootClassName="flex items-center [&_iframe]:max-w-screens-md sm:max-w-full"
          applySizeMapping={true}
        />
      </div>
      <WidgetWrapper>
        <HorizontalStoryPackage
          renderItems={latestMaArticle}
          showSection={false}
          title="Malaysia"
          moreLink={{
            destination: "malaysia",
          }}
          styles={{
            wrapperStyle: "border-pink-600",
            titleStyle: "text-pink-600",
            sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
          }}
        />
      </WidgetWrapper>
      <WidgetWrapper>
        <HorizontalStoryPackage
          renderItems={kakiJalanArticle}
          title="Kaki Jalan"
          moreLink={{
            destination: "tags/kaki-jalan",
          }}
          styles={{
            wrapperStyle: "border-blue-400",
            titleStyle: "text-blue-400",
            sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
          }}
        />
      </WidgetWrapper>
      <WidgetWrapper>
        <UGCWidget homeUGCWidgetContent={UGCBanner} />
      </WidgetWrapper>
      <WidgetWrapper>
        <div className="flex flex-col lg:flex-row gap-xs">
          <div className="w-full lg:max-w-[645px] xl:max-w-[860px]">
            <HorizontalStoryPackage
              renderItems={kakiMakanArticle}
              title="Kaki Makan"
              moreLink={{
                destination: "tags/kaki-makan",
              }}
              styles={{
                wrapperStyle: "border-blue-400",
                titleStyle: "text-blue-400",
                sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
              }}
            />
          </div>
          <div className="flex w-full lg:max-w-[320px] justify-center items-center">
            <CompanionAdsImu1
              uniqueSectionName={adsSectionName}
              pageAdTargetType={pageAdTargetValue}
              index={0}
            />
          </div>
        </div>
      </WidgetWrapper>
      <WidgetWrapper>
        <HorizontalStoryPackage
          renderItems={dnaDuniaArticle}
          title="DNA Dunia Anak"
          moreLink={{
            destination: "tags/dna-dunia-anak",
          }}
          styles={{
            wrapperStyle: "border-blue-350",
            titleStyle: "text-blue-350",
            sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
          }}
        />
      </WidgetWrapper>
      <WidgetWrapper>
        <VerticalStoryPackage
          renderItems={latestGayaArticle}
          title="Gaya Hidup"
          moreLink={{
            destination: "gaya-hidup",
          }}
          styles={{
            wrapperStyle: "border-red-600",
            titleStyle: "text-red-600",
          }}
        />
      </WidgetWrapper>
      <WidgetWrapper>
        <HorizontalStoryPackage
          renderItems={registeredArticle}
          showContentAccessLabel={false}
          title="Pengguna Berdaftar"
          moreLink={{
            destination: "registered",
          }}
          styles={{
            wrapperStyle: "border-purple-300",
            titleStyle: "flex gap-xs items-center text-purple-300 pl-xxs",
            sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
          }}
          titleIcon={{
            src: registeredUser,
            width: 16,
            height: 16,
          }}
        />
      </WidgetWrapper>
      <WidgetWrapper>
        <HorizontalStoryPackage
          renderItems={latestDuniaArticle}
          showSection={false}
          title="Dunia"
          moreLink={{
            destination: "dunia",
          }}
          styles={{
            wrapperStyle: "border-pink-600",
            titleStyle: "text-pink-600",
            sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
          }}
        />
      </WidgetWrapper>
      <WidgetWrapper>
        <PodcastPackage
          bhCustomEventProps={{
            eventCategory: PodcastBHCustomEventCategory.HOME_PAGE,
            eventAction: "click",
          }}
        />
        <VideoPackage />
      </WidgetWrapper>
    </>
  );
}
