import { GE_FEATURE_ENABLE } from "@helper/getEnvVariables";
import BHBasePage, { type BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import { PayloadResponseType } from "./Home.server";
import HomeMetaData from "./HomeMetaData";
import HomePageNew from "./HomePageLayout/HomePageNew";

export function HomePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const {
    UGCBanner,
    gePayload,
    storyQueue,
    latestArticles,
    latestSgArticles,
    latestMaArticles,
    kakiJalanArticles,
    kakiMakanArticles,
    dnaDuniaArticles,
    latestGayaArticles,
    registeredArticles,
    latestDuniaArticles,
  } = dataLoaderResponse.payload;

  const adsSectionName = "home";
  const { gaData, metaTags } = HomeMetaData(adsSectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.HOME;

  const basePageProps: BHBasePageProps = {
    uniqueSectionName: adsSectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
    hasLb1Ads: true,
    ...(parseInt(GE_FEATURE_ENABLE) && {
      bodyWrapperStyle: "my-0 lg:mt-0 mb-3 lg:mb-md",
    }),
    showGEBanner: false,
  };

  const renderHomePage = () => {
    return (
      <HomePageNew
        topStoryQueue={storyQueue.slice(0, 4)}
        latestFreeArticle={latestArticles}
        latestSgArticle={latestSgArticles}
        latestMaArticle={latestMaArticles}
        kakiJalanArticle={kakiJalanArticles}
        kakiMakanArticle={kakiMakanArticles}
        dnaDuniaArticle={dnaDuniaArticles}
        latestGayaArticle={latestGayaArticles}
        registeredArticle={registeredArticles}
        latestDuniaArticle={latestDuniaArticles}
        adsSectionName={adsSectionName}
        pageAdTargetValue={pageAdTargetValue}
        gePayload={gePayload}
        UGCBanner={UGCBanner}
      />
    );
  };

  return <BHBasePage {...basePageProps}>{renderHomePage()}</BHBasePage>;
}
